import React from "react";

const PlayIcon = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20.4267 11.0739L6.5 4.10726C6.3414 4.02904 6.16547 3.99256 5.98884 4.00126C5.81221 4.00996 5.64072 4.06355 5.49057 4.15698C5.34042 4.25041 5.21657 4.38058 5.13073 4.53519C5.04489 4.6898 4.9999 4.86375 5 5.0406V18.9606C4.9999 19.1374 5.04489 19.3114 5.13073 19.466C5.21657 19.6206 5.34042 19.7508 5.49057 19.8442C5.64072 19.9376 5.81221 19.9912 5.98884 19.9999C6.16547 20.0086 6.3414 19.9721 6.5 19.8939L20.4267 12.9273C20.5996 12.8419 20.7452 12.7099 20.847 12.5462C20.9488 12.3824 21.0028 12.1934 21.0028 12.0006C21.0028 11.8078 20.9488 11.6188 20.847 11.455C20.7452 11.2913 20.5996 11.1593 20.4267 11.0739Z"
      fill="currentColor"
    />
  </svg>
);

export default PlayIcon;
