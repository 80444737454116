import React, { useState } from "react";
import ReactPlayer from "react-player";
import PlayIcon from "./icons/PlayIcon";
import PauseIcon from "./icons/PauseIcon";
import "./App.css";

function App() {
  const [playing, setPlaying] = useState(false); // Set initial playing state to false
  const [volume, setVolume] = useState(1.0);

  return (
    <div className="App">
      <ReactPlayer
        url="https://www.youtube.com/watch?v=jfKfPfyJRdk"
        playing={playing}
        volume={volume}
        width="0"
        height="0"
        config={{ youtube: { playerVars: { showinfo: 0, controls: 0 } } }}
      />
      <div className="controlDock">
        <button className="playButton" onClick={() => setPlaying(!playing)}>
          {playing ? (
            <PauseIcon className="playPauseIcon" alt="Pause" />
          ) : (
            <PlayIcon className="playPauseIcon" alt="Play" />
          )}
        </button>
        <input
          className="volumeSlider"
          type="range"
          min={0}
          max={1}
          value={volume}
          onChange={(e) => setVolume(parseFloat(e.target.value))}
          step={0.01}
        />
      </div>
    </div>
  );
}

export default App;
