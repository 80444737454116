import React from "react";

const PauseIcon = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7 4.66669H9.66667C10.219 4.66669 10.6667 5.1144 10.6667 5.66669V18.3334C10.6667 18.8856 10.219 19.3334 9.66667 19.3334H7C6.44772 19.3334 6 18.8856 6 18.3334V5.66669C6 5.1144 6.44772 4.66669 7 4.66669ZM14.3333 4.66669H17C17.5523 4.66669 18 5.1144 18 5.66669V18.3334C18 18.8856 17.5523 19.3334 17 19.3334H14.3333C13.7811 19.3334 13.3333 18.8856 13.3333 18.3334V5.66669C13.3333 5.1144 13.7811 4.66669 14.3333 4.66669Z"
      fill="currentColor"
    />
  </svg>
);

export default PauseIcon;
